$(document).ready(function() {

    'use strict';

    $('.snellinks .mmenu-open').on('click', function(e) {
      e.preventDefault();
      var targetId;

      /*wat is het data-id van de geklikte knop?*/
      var context = $(this);
      var dataid = context.attr("data-id");

      /*vind het element met dezelfde data-id in het menu */
      var target = $('#mm-1 a.mm-next');

			target.each(function(index, item) {
				let itemDataId = $(item).next('a').attr("data-id");
				if (itemDataId == dataid) {
					// simuleer een klik op het gevonden element
					$(item).click();
				}
			});
  	});

	// delegate click event to child item
	$('nav.mobile-menu li.has-children.LABEL > a[data-id]').on('click', function() {
		event.preventDefault();
		//console.log('invoked: ' + $(this).attr('href'));
		var targetAnchor = $(this).closest('li').find('a[data-target]');
		//console.log('targetAnchor : ' + targetAnchor.attr('href') );
		targetAnchor.trigger('click');
	});

});
